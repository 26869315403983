<template>
  <div>
    <v-preloader
        v-show="preloader"
        static
    />
    <div
        v-show="!preloader"
        class="dashboard-filters"
    >
      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <s-select
              id="filters_search_engine"
              v-model="filters.search_engine"
              v-bind="{
                options: searchEngines,
                insideLabel: 'ПС',
                placeholder: 'Выберите ПС',
                clearButton: true
              }"
              @input="updateFilters('search_engine', $event)"
          />
        </div>

        <div class="dashboard-filters__item">
          <v-filter-region
              :regions="regions"
              :value="filters.region_id"
              @input="updateFilters('region_id', $event)"
          />
        </div>

        <div class="dashboard-filters__item">
          <s-select
              id="filters_device"
              v-bind="{
                options: devices,
                insideLabel: 'Устройство',
                placeholder: 'Выберите устройство',
                minWidth: '270px',
                clearButton: true,
                appendToBody: false
              }"
              v-model="filters.is_mobile"
              @input="updateFilters('is_mobile', $event)"
          />
        </div>

        <div class="dashboard-filters__item _right">
          <s-daterangepicker-comparison
              id="filters_date"
              v-bind="{
                placeholder: 'Выберите дату',
                dynamicWidthDrop: true,
                dropPosition: 'bottom-end',
                availablesTo: availableDateTo
              }"
              v-model="filters.date"
              @input="updateFilters('date', $event)"
          />
        </div>
      </div>

      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <v-traffic-source-toggle :value="urlFilters.traffic_source" />
        </div>

        <div class="dashboard-filters__item">
          <v-filter-traffic
              title="Источник"
              filter-name="source"
              :date-filters="dateFilters.one"
              :url-filters="urlFilters"
          />
        </div>

        <div class="dashboard-filters__item">
          <v-filter-traffic
              title="Канал"
              filter-name="medium"
              :date-filters="dateFilters.one"
              :url-filters="urlFilters"
          />
        </div>

        <div
            v-if="!routeBiTraction"
            class="dashboard-filters__item _right"
        >
          <v-button
              v-bind="buttonToggleChartProps"
              @click="$emit('toggle-chart')"
          />
        </div>
      </div>

      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <v-select-tree-segments
              v-if="!isMixedSegments"
              v-bind="{
                ref: 'selectSegments',
                filters,
                insideLabel: 'Сегменты',
                placeholder: 'Выберите сегмент',
                url: segmentFilterUrl,
                filterName,
                customSegments
              }"
              @update-url="updateUrl"
              @loaded="finishLoading"
          />
          <v-select-tree-mixed-segments
              v-if="isMixedSegments"
              v-bind="{
                ref: 'selectSegments',
                filters,
                insideLabel: 'Сегменты',
                placeholder: 'Выберите сегмент',
                url: segmentFilterUrl,
              }"
              @update-url="updateUrl"
              @loaded="finishLoading"
            />
        </div>
      </div>

      <div class="dashboard-filters__line">
        <div class="dashboard-filters__item">
          <v-select-tree-metric
              v-bind="{metrics, metricActive: filters.metric}"
              @update-url="updateUrl"
          />
        </div>

        <div :class="['dashboard-filters__item', {_right: !routeBiTraction}]">
          <v-select-tree-segments
              v-if="!isMixedSegments"
              v-bind="{
                filters,
                insideLabel: 'Контрольная группа',
                placeholder: 'Выберите сегмент',
                multiple: false,
                disabled: filters.all_site || noSegmentsSelected,
                filterName: 'control_group',
                needsData: false,
                url: segmentFilterUrl,
                customSegments
              }"
              @update-url="updateUrl"
          />
          <v-select-tree-mixed-segments
              v-if="isMixedSegments"
              v-bind="{
                filters,
                insideLabel: 'Контрольная группа',
                placeholder: 'Выберите сегмент',
                multiple: false,
                disabled: filters.all_site || noSegmentsSelected,
                filterName: 'control_group',
                needsData: false,
              }"
              @update-url="updateUrl"
          />
        </div>

        <div class="dashboard-filters__item">
          <div class="toggle-filters">
            <div class="toggle-filters__value">
              <v-radio-toggle
                  v-model="filters.all_site"
                  :disabled="noSegmentsSelected"
                  dataforce
                  @input="updateFilters('all_site', String($event))"
              />
            </div>
            <div class="toggle-filters__label">Весь сайт</div>
          </div>
        </div>

        <div
            v-if="routeBiTraction"
            class="dashboard-filters__item _right"
        >
          <v-button
              v-bind="buttonToggleChartProps"
              @click="$emit('toggle-chart')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/const/'
import ROUTE_NAME from '@/router/routeName'

import trafficSourceMixin from '@/mixins/trafficSourceMixin'

import selectTreeSegments from '@/services/components/selectTreeSegments'
import selectTreeMixedSegments from '@/services/components/selectTreeMixedSegments/'
import filterTraffic from '@/services/components/filterTraffic'
import selectTreeMetric from '@/services/components/selectTreeMetric'
import filterRegion from '@/services/components/filterRegion'

export default {
  mixins: [trafficSourceMixin],

  components: {
    'v-select-tree-segments': selectTreeSegments,
    'v-select-tree-mixed-segments': selectTreeMixedSegments,
    'v-filter-traffic': filterTraffic,
    'v-select-tree-metric': selectTreeMetric,
    'v-filter-region': filterRegion
  },

  props: {
    urlFilters: {
      type: Object,
      default: function () {
        return {}
      }
    },

    showChart: {
      type: Boolean,
      default: true
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    devices: {
      type: Array,
      default: () => ([])
    },

    searchEngines: {
      type: Array,
      default: () => ([])
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    segmentFilterUrl: {
      type: String,
      default: ''
    },

    filterName: {
      type: String,
      default: 'catalog_ids'
    },

    customSegments: {
      type: Boolean,
      default: false
    },

    regions: {
      type: Array,
      default: () => ([])
    },

    isMixedSegments: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      ROUTE_NAME,
      filters: {},

      availableDateTo: +this.$moment.utc().startOf('day').subtract(1, 'days').format('x'),

      preloader: true
    }
  },

  computed: {
    routeBiTraction () {
      return this.$route.name === ROUTE_NAME.BI_TRACTION
    },

    buttonToggleChartProps () {
      return {
        name: this.showChart ? 'Скрыть график' : 'Показать график',
        type: 'base',
        size: 'small'
      }
    },

    noSegmentsSelected() {
      return this.routeBiTraction && this.urlFilters.segments && !this.urlFilters.segments.length
    }
  },

  watch: {
    urlFilters () {
      this.setFilters()
    },

    'filters.date' (value) {
      let periodTwo = {...value.two}

      if (!periodTwo.from) {
        periodTwo.from = this.urlFilters.date.two.from
      }
      if (!periodTwo.to) {
        periodTwo.to = this.urlFilters.date.two.to
      }

      this.filters.date.two = {
        from: periodTwo.from,
        to: periodTwo.to
      }
    },

    preloader(to) {
      if (!to) {
        this.$nextTick(() => {
          const select = this.$refs.selectSegments && this.$refs.selectSegments.$refs.selectSegments

          if (select) {
            select.setLabelWidth()
          }
        })
      }
    }
  },

  created () {
    this.setFilters()
  },

  methods: {
    /* Изменение URL фильтров */
    updateFilters(filter, value) {
      switch (filter) {
        case 'date':
          let query = {
            period_one_date_from: this.$moment.utc(+value.one.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
            period_one_date_to: this.$moment.utc(+value.one.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
            period_two_date_from: undefined,
            period_two_date_to: undefined
          }

          if (value.two.from && value.two.to) {
            query.period_two_date_from = this.$moment.utc(+value.two.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
            query.period_two_date_to = this.$moment.utc(+value.two.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
          }

          this.updateUrl(query)
          break

        case 'is_mobile':
          if (value === 0) {
            this.updateUrl({[filter]: String(value)})
          } else {
            this.updateUrl({[filter]: value})
          }
          break

        default:
          this.updateUrl({[filter]: value})
      }
    },

    /* Обновление URL фильтров */
    updateUrl(query) {
      this.$emit('update-url', query)
    },

    setFilters() {
      this.$set(this, 'filters', {
        ...this.urlFilters
      })
    },

    finishLoading () {
      this.preloader = false
      this.$emit('finish-filters-loading')
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-filters {
  display: flex;
  align-items: center;

  &__label {
    margin-left: 10px;
  }

  &__value {
    line-height: 20px;
    font-size: 14px;
    color: #455165;
  }
}
</style>
