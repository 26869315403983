<template>
  <vue-line
      :class="{'_control-group': controlGroup}"
      :mouse-events="['mouseover']"
      @mouseover="$emit('mouseover')"
  >
    <vue-item>
      <div class="query-name">
        <v-table-item-title
            :title="item.url_title"
            :url="item.url_name"
            :level="+item.level"
            :is-listing="isListing"
            :listing-page="item.listingPage"
            :type="item.type_id"
            :entity-count="entityCount"
            class="_ml-0"
            :prefix="controlGroup ? 'Контрольная группа' : undefined"
        />
      </div>
    </vue-item>
    <vue-item
        v-if="!customSegments"
        align="right"
    >
      <template v-if="level">{{ level }}</template>
    </vue-item>
    <vue-item align="right">
      {{ item.one | formatCount }}{{ activeMetric.additional }}
    </vue-item>
    <vue-item align="right">
      {{ item.two | formatCount }}{{ activeMetric.additional }}
    </vue-item>
    <vue-item align="right">
      {{ item.diff | formatCount }}{{ activeMetric.additional }}
    </vue-item>
    <vue-item align="right">
      <vue-element
          type="label"
          :data="item.diff_prc"
          label-note="%"
          locales="ru-RU"
      />
    </vue-item>
    <vue-item align="right">
      <template v-if="!controlGroup && !segmentIsControlGroup && (urlFilters.control_group || urlFilters.all_site)">
        {{ item.diff_cg | formatCount }}{{ activeMetric.additional }}
      </template>
    </vue-item>
    <vue-item align="right">
      <vue-element
          v-if="!controlGroup && !segmentIsControlGroup && (urlFilters.control_group || urlFilters.all_site)"
          type="label"
          :data="item.diff_prc_cg"
          label-note="%"
          locales="ru-RU"
      />
    </vue-item>
    <vue-item align="center">
      <div
          v-if="!controlGroup"
          class="buttons"
      >
        <v-button
            v-bind="{
              size: 'small',
              type: 'text-base',
              paddingLeft: '6px',
              paddingRight: '6px',
              to: { query: routeCG.query }
            }"
            v-tippy
            content="Сделать контрольной группой"
        >
          <icon
              v-bind="{
                name: 'kg',
                fill: '#9D99AA'
              }"
          />
        </v-button>

        <v-button
            v-bind="{
              size: 'small',
              type: 'text-base',
              paddingLeft: '6px',
              paddingRight: '6px',
              to: { query: routeDelete.query }
            }"
            v-tippy
            content="Убрать из сравнения"
        >
          <icon
              v-bind="{
              name: 'delete',
              fill: '#9D99AA'
            }"
          />
        </v-button>
      </div>
    </vue-item>
  </vue-line>
</template>

<script>
import tableItemTitle from '@/services/components/tableItemTitle'
import tableDataCell from '@/services/components/tableDataCell'

import {roundNumber, hmsToSeconds, generateRoute} from '@/function'

export default {
  components: {
    'v-table-item-title': tableItemTitle,
    'v-table-data-cell': tableDataCell
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    comparisonPeriod: {
      type: Boolean,
      default: false
    },

    activeMetric: {
      type: Object,
      default: () => ({})
    },

    level: {
      type: [Number, String],
      default: undefined
    },

    activeSegment: {
      type: Object,
      default: () => ({})
    },

    controlGroup: {
      type: Boolean,
      default: false
    },

    segmentIsControlGroup: {
      type: Boolean,
      default: false
    },

    urlFilters: {
      type: Object,
      default: () => ({})
    },
    segmentFilterName: {
      type: String,
      default: 'catalog_ids'
    },
    customSegments: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isListing () {
      try {
        return this.item.is_listing
      } catch (_) {
        return false
      }
    },

    isMainPage () {
      try {
        return this.item.url_title.toLowerCase().includes('главная')
      } catch (_) {
        return false
      }
    },

    activeSegmentValue () {
      try {
        return {
          one: this.getValue(this.comparisonPeriod ? this.activeSegment[`${this.activeMetric.id}_one`] : this.activeSegment[this.activeMetric.id]),
          two: this.getValue(this.activeSegment[`${this.activeMetric.id}_two`]),
          diff: this.getValue(this.activeSegment[`${this.activeMetric.id}_diff`])
        }
      } catch (_) {
        return {}
      }
    },

    routeCG () {
      let newRoute = generateRoute(this.$route)
      newRoute.query.control_group = this.item.catalog_id
      newRoute.query.all_site = 'false'
      return newRoute
    },

    routeDelete () {
      let newRoute = generateRoute(this.$route)
      newRoute.query[this.segmentFilterName] = this.urlFilters[this.segmentFilterName]

      return this.deleteParam({
        route: newRoute,
        id: this.item.catalog_id
      })
    },

    entityCount () {
      return this.$store.state.entityCountTypes.map(type => {
        return {
          type: type.id,
          count: this.item[`count_${type.id}`] || 0,
          name: type.name
        }
      })
    }
  },

  methods: {
    roundNumber,

    getValue (val) {
      return typeof val === 'string' ? hmsToSeconds(val) : val
    },

    deleteParam ({ route, id }) {
      try {
        let activeFilter = route.query[this.segmentFilterName]

        if (Array.isArray(activeFilter)) {
          let newSegmentsQuery = [...route.query[this.segmentFilterName]]
          const index = newSegmentsQuery.indexOf(String(id))
          if (index > -1) {
            newSegmentsQuery.splice(index, 1)
          }
          route.query[this.segmentFilterName] = newSegmentsQuery
        } else {
          route.query[this.segmentFilterName] = undefined
        }
      } catch (_) {
      }

      return route
    }
  }
}
</script>

<style scoped lang="scss">
.table-line._control-group {
  .table-item {
    background: #F1F7FD !important;
  }
}

.query-name {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 450px;
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
</style>
