var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preloader),expression:"preloader"}],attrs:{"static":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preloader),expression:"!preloader"}],staticClass:"dashboard-filters"},[_c('div',{staticClass:"dashboard-filters__line"},[_c('div',{staticClass:"dashboard-filters__item"},[_c('s-select',_vm._b({attrs:{"id":"filters_search_engine"},on:{"input":function($event){return _vm.updateFilters('search_engine', $event)}},model:{value:(_vm.filters.search_engine),callback:function ($$v) {_vm.$set(_vm.filters, "search_engine", $$v)},expression:"filters.search_engine"}},'s-select',{
              options: _vm.searchEngines,
              insideLabel: 'ПС',
              placeholder: 'Выберите ПС',
              clearButton: true
            },false))],1),_c('div',{staticClass:"dashboard-filters__item"},[_c('v-filter-region',{attrs:{"regions":_vm.regions,"value":_vm.filters.region_id},on:{"input":function($event){return _vm.updateFilters('region_id', $event)}}})],1),_c('div',{staticClass:"dashboard-filters__item"},[_c('s-select',_vm._b({attrs:{"id":"filters_device"},on:{"input":function($event){return _vm.updateFilters('is_mobile', $event)}},model:{value:(_vm.filters.is_mobile),callback:function ($$v) {_vm.$set(_vm.filters, "is_mobile", $$v)},expression:"filters.is_mobile"}},'s-select',{
              options: _vm.devices,
              insideLabel: 'Устройство',
              placeholder: 'Выберите устройство',
              minWidth: '270px',
              clearButton: true,
              appendToBody: false
            },false))],1),_c('div',{staticClass:"dashboard-filters__item _right"},[_c('s-daterangepicker-comparison',_vm._b({attrs:{"id":"filters_date"},on:{"input":function($event){return _vm.updateFilters('date', $event)}},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}},'s-daterangepicker-comparison',{
              placeholder: 'Выберите дату',
              dynamicWidthDrop: true,
              dropPosition: 'bottom-end',
              availablesTo: _vm.availableDateTo
            },false))],1)]),_c('div',{staticClass:"dashboard-filters__line"},[_c('div',{staticClass:"dashboard-filters__item"},[_c('v-traffic-source-toggle',{attrs:{"value":_vm.urlFilters.traffic_source}})],1),_c('div',{staticClass:"dashboard-filters__item"},[_c('v-filter-traffic',{attrs:{"title":"Источник","filter-name":"source","date-filters":_vm.dateFilters.one,"url-filters":_vm.urlFilters}})],1),_c('div',{staticClass:"dashboard-filters__item"},[_c('v-filter-traffic',{attrs:{"title":"Канал","filter-name":"medium","date-filters":_vm.dateFilters.one,"url-filters":_vm.urlFilters}})],1),(!_vm.routeBiTraction)?_c('div',{staticClass:"dashboard-filters__item _right"},[_c('v-button',_vm._b({on:{"click":function($event){return _vm.$emit('toggle-chart')}}},'v-button',_vm.buttonToggleChartProps,false))],1):_vm._e()]),_c('div',{staticClass:"dashboard-filters__line"},[_c('div',{staticClass:"dashboard-filters__item"},[(!_vm.isMixedSegments)?_c('v-select-tree-segments',_vm._b({on:{"update-url":_vm.updateUrl,"loaded":_vm.finishLoading}},'v-select-tree-segments',{
              ref: 'selectSegments',
              filters: _vm.filters,
              insideLabel: 'Сегменты',
              placeholder: 'Выберите сегмент',
              url: _vm.segmentFilterUrl,
              filterName: _vm.filterName,
              customSegments: _vm.customSegments
            },false)):_vm._e(),(_vm.isMixedSegments)?_c('v-select-tree-mixed-segments',_vm._b({on:{"update-url":_vm.updateUrl,"loaded":_vm.finishLoading}},'v-select-tree-mixed-segments',{
              ref: 'selectSegments',
              filters: _vm.filters,
              insideLabel: 'Сегменты',
              placeholder: 'Выберите сегмент',
              url: _vm.segmentFilterUrl,
            },false)):_vm._e()],1)]),_c('div',{staticClass:"dashboard-filters__line"},[_c('div',{staticClass:"dashboard-filters__item"},[_c('v-select-tree-metric',_vm._b({on:{"update-url":_vm.updateUrl}},'v-select-tree-metric',{metrics: _vm.metrics, metricActive: _vm.filters.metric},false))],1),_c('div',{class:['dashboard-filters__item', {_right: !_vm.routeBiTraction}]},[(!_vm.isMixedSegments)?_c('v-select-tree-segments',_vm._b({on:{"update-url":_vm.updateUrl}},'v-select-tree-segments',{
              filters: _vm.filters,
              insideLabel: 'Контрольная группа',
              placeholder: 'Выберите сегмент',
              multiple: false,
              disabled: _vm.filters.all_site || _vm.noSegmentsSelected,
              filterName: 'control_group',
              needsData: false,
              url: _vm.segmentFilterUrl,
              customSegments: _vm.customSegments
            },false)):_vm._e(),(_vm.isMixedSegments)?_c('v-select-tree-mixed-segments',_vm._b({on:{"update-url":_vm.updateUrl}},'v-select-tree-mixed-segments',{
              filters: _vm.filters,
              insideLabel: 'Контрольная группа',
              placeholder: 'Выберите сегмент',
              multiple: false,
              disabled: _vm.filters.all_site || _vm.noSegmentsSelected,
              filterName: 'control_group',
              needsData: false,
            },false)):_vm._e()],1),_c('div',{staticClass:"dashboard-filters__item"},[_c('div',{staticClass:"toggle-filters"},[_c('div',{staticClass:"toggle-filters__value"},[_c('v-radio-toggle',{attrs:{"disabled":_vm.noSegmentsSelected,"dataforce":""},on:{"input":function($event){_vm.updateFilters('all_site', String($event))}},model:{value:(_vm.filters.all_site),callback:function ($$v) {_vm.$set(_vm.filters, "all_site", $$v)},expression:"filters.all_site"}})],1),_c('div',{staticClass:"toggle-filters__label"},[_vm._v("Весь сайт")])])]),(_vm.routeBiTraction)?_c('div',{staticClass:"dashboard-filters__item _right"},[_c('v-button',_vm._b({on:{"click":function($event){return _vm.$emit('toggle-chart')}}},'v-button',_vm.buttonToggleChartProps,false))],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }