var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('component',_vm._b({on:{"input":function($event){return _vm.updateFilters($event)}},scopedSlots:_vm._u([{key:"drop",fn:function({ options, guiValue, selectOption, activeList }){return [_c('div',{staticClass:"select-segments-drop"},[_c('div',{staticClass:"select-drop-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"select-drop-search__input",attrs:{"placeholder":`${_vm.$t('components.segments')['Search']}...`,"autofocus":""},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),(!!_vm.search)?_c('div',{staticClass:"select-drop-search__reset",on:{"click":function($event){$event.stopPropagation();_vm.search = ''}}},[_c('svg',{attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"}})])]):_vm._e(),_c('div',{staticClass:"select-drop-search__icon"},[_c('svg',{attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"clip-rule":"evenodd","d":"M14.71 14h.79l4.99 5L19 20.49l-5-4.99v-.79l-.27-.28A6.471 6.471 0 0 1 9.5 16 6.5 6.5 0 1 1 16 9.5c0 1.61-.59 3.09-1.57 4.23l.28.27zM5 9.5C5 11.99 7.01 14 9.5 14S14 11.99 14 9.5 11.99 5 9.5 5 5 7.01 5 9.5z"}})])])]),(_vm.nestedCatalogSegmentsFiltered && _vm.nestedCatalogSegmentsFiltered.length)?_c('ul',{staticClass:"select-segments-drop-list",attrs:{"id":"select_segments_list"}},_vm._l((_vm.nestedCatalogSegmentsFiltered),function(item,i){return _c('v-tree-node',_vm._b({key:i,on:{"toggle-checkbox":function($event){return _vm.updateCatalogIdsFilter($event, item)}}},'v-tree-node',{
            data: item,
            multiple: _vm.multiple,
            active: _vm.getNodeActiveStatus(item),
            updateCatalogIdsFilterFunc: _vm.updateCatalogIdsFilter,
            filters: _vm.filters,
            filterName: _vm.filterName,
            customSegments: _vm.customSegments,
            featureFlag: _vm.featureFlag,
            getNodeActiveStatus: _vm.getNodeActiveStatus,
            tree: _vm.tree
          },false))}),1):(!!_vm.search)?_c('div',{staticClass:"select-drop-empty"},[_vm._v(" "+_vm._s(_vm.$t('components.segments')['No segments found'])+" ")]):_vm._e()])]}}]),model:{value:(_vm.guiValue),callback:function ($$v) {_vm.guiValue=$$v},expression:"guiValue"}},'component',{
      is: _vm.multiple ? 's-multiselect' : 's-select',
      id: 'select_segments',
      ref: 'selectSegments',
      insideLabel: _vm.insideLabel,
      placeholder: _vm.placeholder,
      maxWidth: '100%',
      minWidth: '250px',
      options: _vm.catalog.items,
      clearButton: true,
      disabled: _vm.disabled,
      dynamicWidthDrop: true,
      minWidthDrop: '100%',
      appendToBody: false,
      dropPosition: _vm.dropPosition,
      dynamicWidth: true,
      theme: _vm.theme
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }