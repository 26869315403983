<template>
  <li class="tree-node">
    <div :class="['tree-node__title', {_hoverable: tree}]">
      <div
          :class="['tree-node__title-wrapper', {'_drop-down': isSelectable && tree}]"
          :style="`padding-left: ${level * 16}px`"
          @click="toggleList"
      >
        <svg
            v-if="tree && isSelectable"
            :class="['tree-node__arrow', { '_open': activeList } ]"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" />
        </svg>

        <span :class="['tree-node__title-text', {'_pl-32': tree && ((!data.items && !customSegments) || (customSegments && data.selectable))}]">{{ data.name }}</span>
      </div>

      <v-checkbox
          v-if="(featureFlag && (multiple || !multiple && data.selectable)) || (!featureFlag && ((customSegments && data.selectable) || !customSegments))"
          class="tree-node__checkbox"
          :value="active"
          :icon="checkboxIcon"
          @input="$emit('toggle-checkbox', $event)"
      />
    </div>

    <ul v-if="showList" v-show="activeList">
      <template v-if="data.items">
        <v-tree-node
          v-for="(item, i) in data.items"
          :key="i"
          v-bind="{
            level: level + 1,
            data: item,
            value: value,
            multiple,
            active: getNodeActiveStatus(item),
            filters,
            updateCatalogIdsFilterFunc,
            filterName,
            customSegments,
            featureFlag,
            getNodeActiveStatus,
            tree
          }"
          @click="click($event)"
          @open="open"
          @toggle-checkbox="updateCatalogIdsFilterFunc($event, item)"
        />
      </template>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'v-tree-node',

  props: {
    /* Значение */
    data: {
      type: [Object, String, Number],
      default: null
    },

    /* Уровень вложенности */
    level: {
      type: Number,
      default: 1
    },

    /* Список выбранных опций */
    value: {
      type: [Array, Number, String]
    },

    /* Множественный выбор */
    multiple: {
      type: Boolean,
      default: true
    },

    /* Флаг активности чекбокса */
    active: {
      type: Boolean,
      default: false
    },

    filters: {
      type: Object,
      default: () => {
        return {}
      }
    },

    updateCatalogIdsFilterFunc: {
      type: Function,
      default: () => false
    },

    filterName: {
      type: String,
      default: 'catalog_ids'
    },

    customSegments: {
      type: Boolean,
      default: false
    },

    featureFlag: {
      type: Boolean,
      default: false
    },

    getNodeActiveStatus: {
      type: Function,
      default: () => false
    },

    tree: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      activeList: false
    }
  },

  computed: {
    showList () {
      return (this.data.items && this.data.items.length) || (this.data.segments && this.data.segments.length)
    },

    checkboxIcon() {
      if (this.featureFlag && this.customSegments && this.multiple && !this.data.selectable) {
        let allSelected = true
        const catalogIdsQuery = [...this.filters[this.filterName]]

        const checkNestedItems = items => {
          if (items && Array.isArray(items)) {
            for (let item of items) {
              if (!item.selectable) {
                if (item.items) {
                  checkNestedItems(item.items)
                } else {
                  allSelected = false
                  break
                }
              } else if (item.selectable && !catalogIdsQuery.includes(item.id)) {
                allSelected = false
                break
              }
            }
          }
        }

        checkNestedItems(this.data.items)

        if (this.active && allSelected) {
          return 'done'
        } else if (this.active && !allSelected) {
          return 'remove'
        } else {
          return undefined
        }
      }

      return undefined
    },

    isSelectable() {
      return (this.data.items && !this.customSegments) || (this.customSegments && !this.data.selectable)
    }
  },

  methods: {
    toggleList () {
      if (this.tree) {
        this.activeList ^= true
      }
    },

    click(data) {
      this.$emit('click', data)
    },

    open () {
      if (!this.activeList) {
        this.activeList = true
        this.$emit('open')
      }
    }
  }
}
</script>
