import ROUTE_NAME from '@/router/routeName'

import {mapGetters, mapActions} from 'vuex'
import treeNode from './treeNode.vue'

export default {
  components: {
    'v-tree-node': treeNode
  },

  props: {
    /* Значение */
    filters: {
      type: Object,
      default: () => {
        return {}
      }
    },

    /* Внутренний лейбл */
    insideLabel: {
      type: String
    },

    /* Плейсхолдер */
    placeholder: {
      type: String
    },

    /* Множественный выбор */
    multiple: {
      type: Boolean,
      default: true
    },

    /* Значение */
    segmentTypes: {
      type: Array,
      default: () => {
        return ['catalog']
      }
    },

    theme: {
      type: String,
      default: 'default'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    filterName: {
      type: String,
      default: 'catalog_ids'
    },

    needsData: {
      type: Boolean,
      default: true
    },

    url: {
      type: String,
      default: ''
    },

    customSegments: {
      type: Boolean,
      default: false
    },

    dropPosition: {
      type: String
    },

    tree: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      /* Значение фильтра */
      guiValue: [],

      /* Прелоадер */
      preloader: false,

      /* Поле поиска */
      search: ''
    }
  },

  computed: {
    ...mapGetters({
      nestedCatalogSegments: 'segments/catalog/nestedCatalogSegmentsDF'
    }),

    nestedCatalogSegmentsFiltered () {
      return this.nestedCatalogSegments({
        search: this.search,
        isCustom: !!this.url
      })
    },

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id || undefined
    },

    catalog () {
      return this.$store.state.segments.catalog[!!this.url ? 'dataDFCustom' : 'dataDF']
    },

    featureFlag() {
      return this.$route.name === ROUTE_NAME.BI_TRACTION && this.$route.query['feature-flag-segments'] === '1'
    }
  },

  async created () {
    if (this.needsData) {
      await this.init()
      await this.$emit('loaded')
    }
  },

  watch: {
    filters () {
      this.setValue(this.filters)
    }
  },

  methods: {
    ...mapActions({
      getTreeCatalog: 'segments/catalog/getTreeCatalog',
      getCatalog: 'segments/catalog/getCatalog'
    }),

    async init () {
      this.preloader = true

      this.setValue(this.filters)

      const query = {
        project_id: this.activeProjectId,
        skip_regionality: 1
      }

      let requests = [
          this.getTreeCatalog({
            url: this.url,
            query
          })
      ]

      if (this.customSegments) {
        requests.push(this.getCatalog({query}))
      }

      await Promise.all(requests)

      this.preloader = false
    },

    setValue (filters) {
      this.$set(this, 'guiValue', filters[this.filterName])
    },

    /* Изменение URL фильтров */
    updateFilters (value) {
      this.updateUrl({[this.filterName]: value})
    },

    /* Обновление URL фильтров */
    updateUrl (query) {
      this.$emit('update-url', query)
    },

    updateCatalogIdsFilter (value, segment) {
      const updateQuery = (query, id) => {
        let newQuery = [...query]
        if (value) {
          newQuery.push(id)
        } else {
          let i = newQuery.indexOf(id)
          if (i !== -1) {
            newQuery.splice(i, 1)
          }
        }

        return newQuery
      }

      let catalogIdsQuery

      if (this.featureFlag && this.multiple && !segment.selectable) {
        catalogIdsQuery = [...this.filters[this.filterName]]

        const checkNestedItems = items => {
          if (items && Array.isArray(items)) {
            items.forEach(item => {
              if (!item.selectable && item.items) {
                checkNestedItems(item.items)
              } else if (item.selectable) {
                catalogIdsQuery = updateQuery(catalogIdsQuery, item.id)
              }
            })
          }
        }

        checkNestedItems(segment.items)
      } else {
        if (this.multiple) {
          catalogIdsQuery = updateQuery([...this.filters[this.filterName]], segment.id)
        } else {
          catalogIdsQuery = value ? segment.id : undefined
        }

        if (!this.multiple) {
          const popper = this.$refs.selectSegments &&
              this.$refs.selectSegments.$refs.container &&
              this.$refs.selectSegments.$refs.container.$refs.popper
          if (popper) {
            popper.doClose()
            popper.updatePopper()
          }
        }
      }

      this.updateUrl({[this.filterName]: catalogIdsQuery})
    },

    getNodeActiveStatus(item) {
      if (this.featureFlag && this.customSegments && this.multiple && !item.selectable) {
        let someSelected = false
        const catalogIdsQuery = [...this.filters[this.filterName]]

        const checkNestedItems = items => {
          if (items && Array.isArray(items)) {
            for (let item of items) {
              if (!item.selectable && item.items) {
                checkNestedItems(item.items)
              } else if (item.selectable && catalogIdsQuery.includes(item.id)) {
                someSelected = true
                break
              }
            }
          }
        }

        checkNestedItems(item.items)

        return someSelected
      } else {
        const filter = this.filters[this.filterName]
        return Array.isArray(filter) ? filter.includes(item.id) : filter === item.id
      }
    }
  }
}
