import { render, staticRenderFns } from "./comparisonTableRow.vue?vue&type=template&id=3a128a36&scoped=true"
import script from "./comparisonTableRow.vue?vue&type=script&lang=js"
export * from "./comparisonTableRow.vue?vue&type=script&lang=js"
import style0 from "./comparisonTableRow.vue?vue&type=style&index=0&id=3a128a36&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a128a36",
  null
  
)

export default component.exports